import { httpApps } from "../helper/http-common";

class CompleteYourRingService {
  SaveDropHint(inputData) {
    return httpApps.post("studBuilderForML/SendStudDropHint", inputData);
  }
  ComplateEaringsSendStudDropHint(inputData) {
    return httpApps.post(
      "studBuilderForML/ComplateEaringsSendStudDropHint",
      inputData
    );
  }
  SaveRequestInfo(inputData) {
    return httpApps.post("studBuilderForML/StudBuilderRequestMoreInfo", inputData);
  }
  ComplateEaringsStudBuilderRequestMoreInfo(inputData) {
    return httpApps.post(
      "studBuilderForML/ComplateEaringsStudBuilderRequestMoreInfo",
      inputData
    );
  }
  SaveEmailAFriend(inputData) {
    return httpApps.post("studBuilderForML/StudBuildersEmailAFriend", inputData);
  }
  ComplateEaringsStudBuilderEmailAFriend(inputData) {
    return httpApps.post(
      "studBuilderForML/ComplateEaringsStudBuilderEmailAFriend",
      inputData
    );
  }
  SaveScheduleAViewing(inputData) {
    return httpApps.post("studBuilderForML/StudBuildersScheduleviewing", inputData);
  }

  GetAppPrevData(inputData) {
    return httpApps.post("RingBuilderForML/GetAppPrevData", inputData);
  }
  StudBuilderRequestCertificate(inputData) {
    return httpApps.post(
      "studBuilderForML/StudBuilderRequestCertificate",
      inputData
    );
  }
  GetMountByGFId(inputData) {
    return httpApps.post("studBuilderForML/GetMountByGFId", inputData);
  }
  DiamondPair(inputData) {
    return httpApps.post("studBuilderForML/DiamondPair", inputData);
  }
  GetStudDropDownData(inputData) {
    return httpApps.post("studBuilderForML/GetStudDropDownData", inputData);
  }
  ChangeProductConfigurationStud(inputData) {
    return httpApps.post(
      "studBuilderForML/ChangeProductConfigurationStud",
      inputData
    );
  }
  CompleteYourEarringSocialLinks(inputData) {
    return httpApps.post(
      "studBuilderForML/CompleteYourEarringSocialLinks",
      inputData
    );
  }
  GetAppPrevDataonSelectYourSetting(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetAppPrevDataonSelectYourSetting",
      inputData
    );
  }
}

export default new CompleteYourRingService();
