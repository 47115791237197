import { httpApps, httpAdminCompanyLocation } from "../helper/http-common";

class ChooseSettingServices {
  GetLocationDetails(inputData) {
    return httpAdminCompanyLocation.post(
      "/Profile/LocationLoadDetailsForML",
      inputData
    );
  }
  GetAllStudMount(inputData) {
    return httpApps.post("studBuilderForML/GetAllStudMount", inputData);
  }
  LoadViewDiamonds(inputData) {
    return httpApps.post("studBuilderForML/LoadViewDiamonds", inputData);
  }
  GetDealerInformation(inputData) {
    return httpApps.post("DiamondLinkForML/GetDealerInformation", inputData);
  }
  GetDealerLocationData(inputData) {
    return httpApps.post("DiamondLinkForML/GetDealerLocationData", inputData);
  }
  BindTimeDropDown(inputData) {
    return httpApps.post("studBuilderForML/BindTimeDropDown", inputData);
  }
  DiamondTracking(inputData) {
    return httpApps.post("DiamondLinkForML/DiamondTracking", inputData);
  }
  StudDiamondTracking(inputData) {
    return httpApps.post("DiamondLinkForML/DiamondTracking_Pair", inputData);
  }
}

export default new ChooseSettingServices();
